import * as React from "react";

interface DefaultPageSectionStyle {
  backgroundColor?: string;
  color?: string;
  padding?: string | number;
  paddingTop?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
  paddingRight?: string | number;
}

export default class DefaultPageSection extends React.Component<
  {
    style?: DefaultPageSectionStyle;
    title?: string;
    hasHorizontalPadding?: boolean;
    refObj?: React.RefObject<HTMLDivElement>;
    id?: string;
  },
  {}
> {
  render() {
    const hasHorizontalPadding: boolean =
      this.props.hasHorizontalPadding === undefined ||
      this.props.hasHorizontalPadding === null
        ? true
        : this.props.hasHorizontalPadding;
    return (
      <div
        id={this.props.id}
        ref={this.props.refObj}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "150px",
          paddingBottom: "150px",
          paddingLeft: hasHorizontalPadding ? 20 : 0,
          paddingRight: hasHorizontalPadding ? 20 : 0,
          ...this.props.style
        }}
      >
        {this.props.title ? (
          <div
            style={{
              fontWeight: 300,
              fontSize: 32,
              width: "85%",
              textAlign: "left"
            }}
          >
            {this.props.title}
          </div>
        ) : (
          <div />
        )}
        {this.props.children}
      </div>
    );
  }
}
