import * as React from "react";

import MediaQuery from "react-responsive";

import logo from "../images/bannerLogo.png";

import CustomButton from "./CustomButton";

interface FooterProps {
  onHome: () => void;
  onAbout: () => void;
  onOurStory: () => void;
  onOurCompetenceNetwork: () => void;
  onOurValues: () => void;
  onHowWeWork: () => void;
  onWhatWeDo: () => void;
  onGrowWithUs: () => void;
  onConnect: () => void;
  refObj: React.RefObject<HTMLDivElement>;
}

export default class Footer extends React.Component<FooterProps> {
  navElem(text: string, onClick: () => void, mobilePadding?: boolean) {
    const padding = mobilePadding ? "10px 0" : "0";
    return (
      <div
        onClick={onClick}
        style={{
          cursor: "pointer",
          padding
        }}
      >
        {text}
      </div>
    );
  }

  render() {
    const footerContentLarge = [
      <div
        key="footerLogo"
        style={{
          display: "flex",
          flex: 4,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <img src={logo} width={200} style={{ filter: "brightness(200)" }} />
        <a
          style={{
            paddingTop: 10,
            color: "rgba(255, 255, 255, 0.5)",
            cursor: "pointer"
          }}
          onClick={() => (location.href = "mailto:info@widenadvisory.com")}
        >
          info@widenadvisory.com
        </a>
      </div>,
      <hr
        key="footerDividerLeft"
        style={{
          width: "2px",
          background: "rgb(255, 255, 255)",
          border: "none"
        }}
      />,
      <div
        key="footer"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 4,
          padding: "0 20px 0 20px"
        }}
      >
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Home", this.props.onHome)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("About", this.props.onAbout)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Our Story", this.props.onOurStory)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem(
          "Our Competence Network",
          this.props.onOurCompetenceNetwork
        )}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Our Values", this.props.onOurValues)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("How We Work", this.props.onHowWeWork)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("What We Do", this.props.onWhatWeDo)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Grow with us", this.props.onGrowWithUs)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Connect", this.props.onConnect)}
      </div>
      // <hr
      //   key="footerDividerRight"
      //   style={{
      //     width: "2px",
      //     background: "rgb(255, 255, 255)",
      //     border: "none"
      //   }}
      // />,
      // <div
      //   key="footerContact"
      //   style={{
      //     display: "flex",
      //     flexDirection: "column",
      //     flex: 5,
      //     padding: "0 20px 0 20px"
      //   }}
      // >
      //   <div
      //     style={{
      //       paddingBottom: "20px",
      //       color: "#fff",
      //       fontWeight: "bold"
      //     }}
      //   >
      //     <div style={{ fontWeight: "lighter" }}>
      //       We love good coffee and a good conversation.
      //       <br />
      //       Let's connect
      //     </div>
      //   </div>
      //   <form
      //     style={{
      //       display: "flex",
      //       flexDirection: "column"
      //     }}
      //   >
      //     <label style={{ paddingRight: "10px", paddingBottom: 5 }}>Name</label>
      //     <input
      //       type="text"
      //       name="name"
      //       style={{
      //         border: "none",
      //         outline: "none",
      //         padding: "10px",
      //         backgroundColor: "rgba(255, 255, 255, 0.25)"
      //       }}
      //     />
      //     <div style={{ paddingBottom: 10 }} />
      //     <label style={{ paddingRight: "10px", paddingBottom: 5 }}>
      //       Email
      //     </label>
      //     <input
      //       type="text"
      //       name="name"
      //       style={{
      //         border: "none",
      //         outline: "none",
      //         padding: "10px",
      //         backgroundColor: "rgba(255, 255, 255, 0.25)"
      //       }}
      //     />
      //     <div style={{ paddingBottom: 10 }} />
      //     <label style={{ paddingRight: "10px", paddingBottom: 5 }}>
      //       Message
      //     </label>
      //     <textarea
      //       style={{
      //         border: "none",
      //         outline: "none",
      //         padding: "10px",
      //         backgroundColor: "rgba(255, 255, 255, 0.25)",
      //         height: 75
      //       }}
      //       cols={5}
      //     />
      //     <div style={{ paddingBottom: 10 }} />
      //     <CustomButton
      //       backgroundColor="rgb(219, 80, 74)"
      //       hoverBackgroundColor="transparent"
      //       hoverBorder="2px solid rgb(219, 80, 74)"
      //     >
      //       Submit
      //     </CustomButton>
      //   </form>
      // </div>
    ];
    const footerContentSmall = [
      <div
        key="footerLogo"
        style={{
          display: "flex",
          flex: 4,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <img src={logo} width={200} style={{ filter: "brightness(200)" }} />
        <a
          style={{
            paddingTop: 10,
            color: "rgba(255, 255, 255, 0.5)",
            cursor: "pointer"
          }}
          onClick={() => (location.href = "mailto:info@widenadvisory.com")}
        >
          info@widenadvisory.com
        </a>
      </div>,
      <hr
        key="footerDividerTop1234"
        style={{
          height: "2px",
          width: "100%",
          background: "rgb(255, 255, 255)",
          border: "none",
          margin: "20px 0"
        }}
      />,
      <div
        key="footer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          justifyContent: "center",
          flex: 4,
          padding: "0 20px 0 20px"
        }}
      >
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Home", this.props.onHome)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("About", this.props.onAbout)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Our Story", this.props.onOurStory)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem(
          "Our Competence Network",
          this.props.onOurCompetenceNetwork
        )}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Our Values", this.props.onOurValues)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("How We Work", this.props.onHowWeWork)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("What We Do", this.props.onWhatWeDo)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Grow with us", this.props.onGrowWithUs)}
        <div style={{ paddingBottom: 10 }} />
        {this.navElem("Connect", this.props.onConnect)}
      </div>
      // <hr
      //   key="footerDividerBottom"
      //   style={{
      //     height: "2px",
      //     width: "100%",
      //     background: "rgb(255, 255, 255)",
      //     border: "none",
      //     margin: "20px 0"
      //   }}
      // />,
      // <div
      //   key="footerContact"
      //   style={{
      //     display: "flex",
      //     flexDirection: "column",
      //     flex: 5,
      //     padding: "0 20px 0 20px"
      //   }}
      // >
      //   <div
      //     style={{
      //       paddingBottom: "20px",
      //       color: "#fff",
      //       fontWeight: "bold"
      //     }}
      //   >
      //     <div style={{ fontWeight: "lighter" }}>
      //       We love good coffee and a good conversation.
      //       <br />
      //       Let's connect
      //     </div>
      //   </div>
      //   <form
      //     style={{
      //       display: "flex",
      //       flexDirection: "column"
      //     }}
      //   >
      //     <label style={{ paddingRight: "10px", paddingBottom: 5 }}>Name</label>
      //     <input
      //       type="text"
      //       name="name"
      //       style={{
      //         border: "none",
      //         outline: "none",
      //         padding: "10px",
      //         backgroundColor: "rgba(255, 255, 255, 0.25)"
      //       }}
      //     />
      //     <div style={{ paddingBottom: 10 }} />
      //     <label style={{ paddingRight: "10px", paddingBottom: 5 }}>
      //       Email
      //     </label>
      //     <input
      //       type="text"
      //       name="name"
      //       style={{
      //         border: "none",
      //         outline: "none",
      //         padding: "10px",
      //         backgroundColor: "rgba(255, 255, 255, 0.25)"
      //       }}
      //     />
      //     <div style={{ paddingBottom: 10 }} />
      //     <label style={{ paddingRight: "10px", paddingBottom: 5 }}>
      //       Message
      //     </label>
      //     <textarea
      //       style={{
      //         border: "none",
      //         outline: "none",
      //         padding: "10px",
      //         backgroundColor: "rgba(255, 255, 255, 0.25)",
      //         height: 75
      //       }}
      //       cols={5}
      //     />
      //     <div style={{ paddingBottom: 10 }} />
      //     <CustomButton
      //       backgroundColor="rgb(219, 80, 74)"
      //       hoverBackgroundColor="transparent"
      //       hoverBorder="2px solid rgb(219, 80, 74)"
      //     >
      //       Submit
      //     </CustomButton>
      //   </form>
      // </div>
    ];
    return (
      <div style={{ backgroundColor: "rgb(10,53,77)" }} ref={this.props.refObj}>
        <MediaQuery minWidth={720}>
          <div
            style={{
              backgroundColor: "rgb(40,83,107)",
              display: "flex",
              justifyContent: "space-between",
              padding: "40px",
              color: "rgba(255, 255, 255, 0.5)"
            }}
          >
            {footerContentLarge}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={719}>
          <div
            style={{
              backgroundColor: "rgb(40,83,107)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "40px",
              color: "rgba(255, 255, 255, 0.5)"
            }}
          >
            {footerContentSmall}
          </div>
        </MediaQuery>
        <div
          style={{
            textAlign: "center",
            color: "rgba(255, 255, 255, 0.5)",
            padding: "20px 0px 20px 0px",
            fontWeight: "lighter"
          }}
        >
          © Copyright Widen Advisory | Terms of use - Cookie Policy
        </div>
      </div>
    );
  }
}
