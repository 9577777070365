import * as React from "react";

export default class CustomButton extends React.Component<
  {
    hoverBorder?: string;
    hoverBackgroundColor?: string;
    backgroundColor: string;
    border?: string;
    onClick?: () => void;
  },
  { hover: boolean }
> {
  state: { hover: boolean };

  constructor(props: {
    hoverBorder?: string;
    hoverBackgroundColor?: string;
    backgroundColor: string;
    border?: string;
    onClick?: () => void;
  }) {
    super(props);
    this.state = {
      hover: false
    };
    this.onHover = this.onHover.bind(this);
  }

  onHover(hover: boolean) {
    this.setState({ hover });
  }

  render() {
    const backgroundColor = !this.state.hover
      ? this.props.backgroundColor
      : this.props.hoverBackgroundColor || this.props.backgroundColor;
    const border = !this.state.hover
      ? this.props.border || "none"
      : this.props.hoverBorder || "none";
    return (
      <div
        style={{
          cursor: "pointer",
          textAlign: "center",
          backgroundColor,
          outline: border,
          padding: "10px 0 10px"
        }}
        onMouseEnter={() => this.onHover(true)}
        onMouseLeave={() => this.onHover(false)}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    );
  }
}
