import * as React from "react";

export default class TextBlock extends React.Component<{
  refObj?: React.RefObject<HTMLDivElement>;
}> {
  render() {
    return (
      <div
        ref={this.props.refObj}
        style={{
          marginTop: 50,
          width: "85%",
          lineHeight: 1.5
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
