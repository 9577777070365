import * as React from "react";

import organizationalDesign from "../images/widens_organization_design_model.png";
import offering from "../images/Widen_Offering.png";
import blueW from "../images/W_blue.png";
import yellowW from "../images/W_yellow.png";
import redW from "../images/W_red.png";
import equipMe from "../images/equip_me.png";
import enableMe from "../images/enable_me.png";
import guideMe from "../images/guide_me.png";
import workWithMe from "../images/work_with_me.png";
import doItForMe from "../images/do_it_for_me.png";
import walkStairs from "../images/walk_stairs.jpg";
import gradarLogo from "../images/gradar.png";
import orgvueLogo from "../images/orgvue.png";
import climbingAdventureImg from "../images/climbing_adventure.jpg";
import searchIcon from "../images/search_icon.png";
import peopleWebIcon from "../images/people_web.png";
import mapLocationIcon from "../images/map_location.png";

import HomeImageScroller from "../components/HomeImageScroller";
import Footer from "../components/Footer";
import DefaultPageSection from "../components/DefaultPageSection";
import TextBlock from "../components/TextBlock";
import CustomButton from "../components/CustomButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faSearch } from "@fortawesome/free-solid-svg-icons";

import MediaQuery from "react-responsive";

import TopMenuBar from "../components/TopMenuBar";

class HomePage extends React.Component<
  {},
  {
    menuBarHeight: number;
    walkingStairsImgY: number;
    climbingAdventureImgY: number;
  }
> {
  public state = {
    menuBarHeight: 0,
    walkingStairsImgY: 0,
    climbingAdventureImgY: 0
  };
  private homeRef: React.RefObject<HTMLDivElement>;
  private aboutRef: React.RefObject<HTMLDivElement>;
  private howWeWorkRef: React.RefObject<HTMLDivElement>;
  private ourCompetenceNetworkRef: React.RefObject<HTMLDivElement>;
  private ourValuesRef: React.RefObject<HTMLDivElement>;
  private ourStoryRef: React.RefObject<HTMLDivElement>;
  private whatWeDoRef: React.RefObject<HTMLDivElement>;
  private growWithUsRef: React.RefObject<HTMLDivElement>;
  private connectRef: React.RefObject<HTMLDivElement>;

  private walkingStairsRef: React.RefObject<HTMLImageElement>;
  private climbingAdventureImgRef: React.RefObject<HTMLImageElement>;

  constructor(props: {}) {
    super(props);
    this.homeRef = React.createRef<HTMLDivElement>();
    this.aboutRef = React.createRef<HTMLDivElement>();
    this.howWeWorkRef = React.createRef<HTMLDivElement>();
    this.ourCompetenceNetworkRef = React.createRef<HTMLDivElement>();
    this.ourValuesRef = React.createRef<HTMLDivElement>();
    this.ourStoryRef = React.createRef<HTMLDivElement>();
    this.whatWeDoRef = React.createRef<HTMLDivElement>();
    this.growWithUsRef = React.createRef<HTMLDivElement>();
    this.connectRef = React.createRef<HTMLDivElement>();
    this.walkingStairsRef = React.createRef<HTMLImageElement>();
    this.climbingAdventureImgRef = React.createRef<HTMLImageElement>();
    this.onWindowScroll = this.onWindowScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onWindowScroll);
  }

  onWindowScroll() {
    const windowHeight = window.innerHeight;
    const stairsElem: HTMLImageElement | null = this.walkingStairsRef.current;
    const workWithUsElem: HTMLImageElement | null = this.climbingAdventureImgRef
      .current;
    if (stairsElem && this.isVisible(stairsElem)) {
      const imgTopY = stairsElem.getBoundingClientRect().top;
      const walkingStairsImgY = (imgTopY / windowHeight) * 100;
      this.setState({
        walkingStairsImgY:
          walkingStairsImgY < 0
            ? 0
            : walkingStairsImgY > 100
            ? 100
            : walkingStairsImgY
      });
    }
    if (workWithUsElem && this.isVisible(workWithUsElem)) {
      const imgTopY = workWithUsElem.getBoundingClientRect().top;
      const climbingAdventureImgY = ((imgTopY * 0.8) / windowHeight) * 100;
      this.setState({
        climbingAdventureImgY:
          climbingAdventureImgY < 35
            ? 35
            : climbingAdventureImgY > 100
            ? 100
            : climbingAdventureImgY
      });
    }
  }

  isVisible(elem: HTMLElement) {
    var rect = elem.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  scrollToRef(ref: React.RefObject<HTMLDivElement>, topOffset?: number) {
    if (ref.current) {
      topOffset = topOffset || 0;
      window.scrollTo(0, ref.current.offsetTop + topOffset);
    }
  }

  render() {
    return (
      <div style={{ textAlign: "justify", lineHeight: 1.5 }}>
        <div
          style={{
            position: "fixed",
            right: 40,
            bottom: 40,
            width: 60,
            height: 60,
            backgroundColor: "rgb(50, 80, 120)",
            color: "#fff",
            textAlign: "center",
            borderRadius: "50%",
            border: "1px solid #000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          }}
          onClick={() => window.scrollTo(0, 0)}
        >
          <div>
            <FontAwesomeIcon icon={faChevronUp} />
          </div>
        </div>
        <TopMenuBar
          onHome={() => this.scrollToRef(this.homeRef)}
          onAbout={() => this.scrollToRef(this.aboutRef)}
          onHowWeWork={() => this.scrollToRef(this.howWeWorkRef, 150)}
          onOurCompetenceNetwork={() =>
            this.scrollToRef(this.ourCompetenceNetworkRef, 150)
          }
          onOurValues={() => this.scrollToRef(this.ourValuesRef, 150)}
          onOurStory={() => this.scrollToRef(this.ourStoryRef, 150)}
          onConnect={() => (location.href = "mailto:info@widenadvisory.com")}
          onGrowWithUs={() => this.scrollToRef(this.growWithUsRef, 150)}
          onWhatWeDo={() => this.scrollToRef(this.whatWeDoRef, 150)}
        />
        <HomeImageScroller />
        <div
          style={{
            borderLeft: "3px solid rgba(0, 0, 0, 0.2)",
            padding: 10,
            margin: "75px auto",
            width: "85%",
            fontWeight: 300,
            fontSize: 23.5
          }}
        >
          We are an organization design firm. We analyze, design, and transform
          client organizations to unlock business value, reduce costs, and
          maximize people potential, by looking at organizations from a broad
          perspective through the 9 dimensions of our Organization Architect
          <span style={{ fontSize: 12, verticalAlign: "top" }}>©</span> model
        </div>
        <DefaultPageSection
          refObj={this.homeRef}
          hasHorizontalPadding={false}
          style={{
            paddingTop: 0
          }}
        >
          <div
            style={{
              fontWeight: 300,
              fontSize: 32,
              width: "85%",
              textAlign: "left"
            }}
          >
            Widen's Organization Architect
            <span style={{ fontSize: 12, verticalAlign: "top" }}>©</span> Model
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              src={organizationalDesign}
              width="85%"
              style={{ margin: "50px 0" }}
            />
          </div>
          <TextBlock>
            We <i>wire</i> your organization to outperform by blending the art
            and science of organization design & transformation by addressing
            the 9 main levers of organizational performance. We go beyond the
            processes, structures and reporting lines, and also look at the
            informal aspects of how your organization behaves at all levels.
            Enhanced <i>wiring</i> within and across the 9 dimensions positively
            impacts your business and people performance.
          </TextBlock>
          <img
            src={walkStairs}
            ref={this.walkingStairsRef}
            style={{
              margin: "100px 0 0 0",
              width: "100vw",
              height: 400,
              objectFit: "cover",
              objectPosition: `50% ${this.state.walkingStairsImgY}%`
            }}
          />
        </DefaultPageSection>
        <DefaultPageSection
          title="About Widen Advisory"
          refObj={this.aboutRef}
          style={{ paddingTop: 0 }}
        >
          <TextBlock>
            We are an organization design firm and guide our clients across
            industries and sectors to increase their business & people
            performance. We operate from the heart of Europe and together with
            our technology & consulting partners, we have a network of over 50
            experienced advisors in Europe, US and APAC and provide our clients
            with impactful services, state-of-the-art technology and products.
          </TextBlock>
        </DefaultPageSection>
        <DefaultPageSection
          title="Our story"
          refObj={this.ourStoryRef}
          style={{ backgroundColor: "rgb(40,83,107)", color: "#fff" }}
        >
          <TextBlock>
            Our core offering stems from our passion: understanding, designing
            and transforming organizations. Working with clients to improve
            their organizational performance. Co-creating bespoke solutions
            where needed to address the underlying organizational challenges at
            hand. Working as a team in an informal, but highly professional
            setting. And having fun while at it.
            <br />
            <br />
            We started our boutique consulting firm from a growing realization
            that the traditional Big Consulting model is outdated. Clients,
            small and large, no longer want a one-size-fits-all mentality. They
            seek experienced but down-to-earth advisors, strong IP, actionable
            advice and value for money. That’s why our value proposition is both
            high-touch and high-tech: we offer a unique blend of innovative
            technology, industry knowledge & data-driven organization design
            expertise. We team up with trusted partner firms to create an open,
            client-centered consulting ecosystem.
            <br />
            <br />
            We’re a balanced team of industry experts and business advisors with
            a broad exposure to various sectors & organizations. All our
            advisors have been trained in global consulting power houses and
            have a successful track record in organization design &
            transformation projects.
          </TextBlock>
        </DefaultPageSection>
        <DefaultPageSection
          title="Widen Advisory's Competence Network"
          refObj={this.ourCompetenceNetworkRef}
        >
          <TextBlock>
            Building on each other’s strengths is in our DNA. With this belief
            and motivation, we grow and nurture an ecosystem of partners who
            expand our international reach and/or provide innovative
            technological solutions. It’s what enables us to be both high-touch
            and high tech.
          </TextBlock>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "85%"
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: "100%",
                cursor: "pointer"
              }}
              onClick={() => window.open("https://www.gradar.com/en/")}
            >
              <img src={gradarLogo} width="40%" />
            </div>
            <div style={{ width: 50 }} />
            <div
              style={{
                textAlign: "center",
                width: "100%",
                cursor: "pointer"
              }}
              onClick={() => window.open("https://www.orgvue.com")}
            >
              <img src={orgvueLogo} width="40%" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "85%",
              color: "rgba(0,0,0,0.7)",
              fontStyle: "italic",
              fontWeight: 300
            }}
          >
            <div style={{ lineHeight: 1.5, alignSelf: "start", flex: 1 }}>
              Gradar is an analytical job grading system. With gradar, you can
              evaluate all positions, from the unskilled worker to the division
              head in any type and size of organisation. Gradar is easy to use
              and allows for a transparent and comprehensible job grading. Its
              modern web-based application guides you intuitively through your grading process. This puts you in
              charge and gives you the full control over your data and the
              process of position evaluation.
            </div>
            <div style={{ width: 50 }} />
            <div style={{ lineHeight: 1.5, alignSelf: "start", flex: 1 }}>
              In an era of constant change, getting ahead beats playing catch
              up. OrgVue gives you control of your workforce: how it's
              organized, how it operates, and how it can do better. For the
              first time, HR, Finance, and the business can come together to
              plan the future organization, ensuring the workforce is aligned to
              the operating strategy.
            </div>
          </div>
        </DefaultPageSection>
        <DefaultPageSection
          title="Our Values"
          refObj={this.ourValuesRef}
          style={{ backgroundColor: "rgb(162,53,43)", color: "#fff" }}
        >
          <TextBlock>
            <b>Daring:</b>
            <br />
            We’re courageous, and we’re not afraid to challenge your
            organization’s status quo, or to share inconvenient facts in a
            constructive mindset.
            <br />
            <br />
            <b>Insightful:</b>
            <br />
            We love to dive into the deeper challenges and advise you on how to
            address the true drivers of organizational performance.
            <br />
            <br />
            <b>Committed:</b>
            <br />
            We’re committed to results, and willing to go the extra mile in
            order to help you get things worked out.
            <br />
            <br />
            <b>Entrepreneurial:</b>
            <br />
            We have a sustainable growth mindset. We want to help you and
            ourselves grow as a business, as an organization, and as a team.
            <br />
            <br />
            <b>No-nonsense:</b>
            <br />
            We’re open and direct in our style. Down-to-earth & pragmatic in our
            approach. No expensive theories, but tangible results.
          </TextBlock>
        </DefaultPageSection>
        <DefaultPageSection title="How We Work" refObj={this.howWeWorkRef}>
          <TextBlock>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 20
              }}
            >
              <img src={equipMe} width={70} height={70} />
              <div style={{ paddingLeft: 20 }}>
                <b>Equip me | Methodologies and Tools</b>
                <br />
                We will provide you with the methodologies, tools and data for
                organizational effectiveness.
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 20
              }}
            >
              <img src={enableMe} width={70} height={70} />
              <div style={{ paddingLeft: 20 }}>
                <b>Enable me | Skills Transfer and Capability Development</b>
                <br />
                We will transfer skills through a ‘learning by doing’ approach
                and provide formal trainings.
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 20
              }}
            >
              <img src={guideMe} width={70} height={70} />
              <div style={{ paddingLeft: 20 }}>
                <b>Guide me | Project steering and Governance</b>
                <br />
                We help you define the roadmap, steer the journey and ensure
                governance within and across projects.
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 20
              }}
            >
              <img src={workWithMe} width={70} height={70} />
              <div style={{ paddingLeft: 20 }}>
                <b>Work with me | Collaboration and Support</b>
                <br />
                We work with you in an embedded project team to address the
                organizational challenges.
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 20
              }}
            >
              <img src={doItForMe} width={70} height={70} />
              <div style={{ paddingLeft: 20 }}>
                <b>Do it for me | Insightful and courageous advice</b>
                <br />
                We intervene as external and objective partners in our fields of
                expertise.
              </div>
            </div>
          </TextBlock>
        </DefaultPageSection>
        <DefaultPageSection
          title="What We Do"
          refObj={this.whatWeDoRef}
          style={{ backgroundColor: "#d7c7bb", color: "#000" }}
        >
          <MediaQuery minWidth={1085}>
            <div style={{ width: "85%", marginTop: 50 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: 75
                }}
              >
                <div style={{ flex: 1 }}>
                  <div style={{ textAlign: "center" }}>
                    <img src={searchIcon} width={90} height={90} />
                    <br />
                    <br />
                    <b>ANALYZE</b>
                    <br />
                    <br />
                  </div>
                  Applying Widen’s Organization Architecture methodology, we
                  deep dive into the tangible & non-tangible aspects of your
                  organization, provide objective insights and help surface
                  challenges and opportunities.
                </div>
                <div style={{ width: 100 }} />
                <div style={{ flex: 1 }}>
                  <div style={{ textAlign: "center" }}>
                    <img src={peopleWebIcon} width={90} height={90} />
                    <br />
                    <br />
                    <b>DESIGN</b>
                    <br />
                    <br />
                  </div>
                  We (co-)design futureproof organization & people policies,
                  structures, processes & thinking frameworks to help you create
                  clarity, accountability and commitment across your business.
                  <div style={{ paddingTop: 75 }}>
                    <CustomButton
                      backgroundColor="rgb(219, 80, 74)"
                      hoverBackgroundColor="rgba(219, 80, 74, 0.5)"
                      hoverBorder="2px solid rgba(219, 80, 74, 0.5)"
                      onClick={() =>
                        (location.href = "mailto:info@widenadvisory.com")
                      }
                    >
                      <div
                        style={{
                          color: "#fff",
                          padding: "10px 50px 10px 50px"
                        }}
                      >
                        Let's connect
                      </div>
                    </CustomButton>
                  </div>
                </div>
                <div style={{ width: 100 }} />
                <div style={{ flex: 1 }}>
                  <div style={{ textAlign: "center" }}>
                    <img src={mapLocationIcon} width={90} height={90} />
                    <br />
                    <br />
                    <b>TRANSFORM</b>
                    <br />
                    <br />
                  </div>
                  We use our cross-industry implementation experience to support
                  you in re-wiring the organization, by planning and executing
                  the transformation journey.
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1084}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "85%",
                marginTop: 50
              }}
            >
              <div style={{ flex: 1, paddingBottom: 50 }}>
                <div style={{ textAlign: "center" }}>
                  <img src={searchIcon} width={90} height={90} />
                  <br />
                  <br />
                  <b>ANALYZE</b>
                  <br />
                  <br />
                </div>
                Applying Widen’s Organization Architecture methodology, we deep
                dive into the tangible & non-tangible aspects of your
                organization’s, provide objective insights and help surface
                challenges and opportunities.
              </div>
              <div style={{ width: 100 }} />
              <div style={{ flex: 1, paddingBottom: 50 }}>
                <div style={{ textAlign: "center" }}>
                  <img src={peopleWebIcon} width={90} height={90} />
                  <br />
                  <br />
                  <b>DESIGN</b>
                  <br />
                  <br />
                </div>
                We (co-)design futureproof organization & people policies,
                structures, processes & thinking frameworks to help you create
                clarity, accountability and commitment across your business.
              </div>
              <div style={{ width: 100 }} />
              <div style={{ flex: 1, paddingBottom: 75 }}>
                <div style={{ textAlign: "center" }}>
                  <img src={mapLocationIcon} width={90} height={90} />
                  <br />
                  <br />
                  <b>TRANSFORM</b>
                  <br />
                  <br />
                </div>
                We use our cross-industry implementation experience to support
                you in re-wiring the organization, by planning and executing the
                transformation journey.
              </div>
              <CustomButton
                backgroundColor="rgb(219, 80, 74)"
                hoverBackgroundColor="rgba(219, 80, 74, 0.5)"
                hoverBorder="2px solid rgba(219, 80, 74, 0.5)"
                onClick={() =>
                  (location.href = "mailto:info@widenadvisory.com")
                }
              >
                <div style={{ color: "#fff", padding: "10px 50px 10px 50px" }}>
                  Let's connect
                </div>
              </CustomButton>
            </div>
          </MediaQuery>
        </DefaultPageSection>
        {/* <DefaultPageSection
          title="What We Do"
          refObj={this.whatWeDoRef}
          style={{ backgroundColor: "#d7c7bb", color: "#000" }}
        >
          <div
            style={{
              marginTop: 50,
              minWidth: "50%",
              maxWidth: "70%",
              height: "auto"
            }}
          >
            <img src={offering} style={{ width: "100%" }} />
          </div>
          <div style={{ height: 150 }} />
          <MediaQuery minWidth={"650px"}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "85%"
              }}
            >
              <div style={{ flex: 2 }}>
                <img src={blueW} style={{ width: "100%" }} />
              </div>
              <div style={{ width: 50 }} />
              <div style={{ flex: 10 }}>
                <i style={{ fontSize: "1.15em" }}>
                  Applying Widen’s Organization Architecture methodology, we
                  deep dive into the tangible & non-tangible aspects of your
                  organization’s, provide objective insights and help surface
                  challenges and opportunities.
                </i>
                <div style={{ paddingTop: 50 }}>
                  Examples of client requests:
                  <ul>
                    <li>
                      Market conditions have pushed us to review our operating
                      strategy. Can you help us understand if and how that will
                      affect our future structure and required capabilities?
                    </li>
                    <li>
                      We’re in full change mode, but our efforts don’t trickle
                      down to the floor. Can you help us identify what’s getting
                      in the way?
                    </li>
                    <li>
                      Can you help us determine the right size, shape, and spend
                      of our workforce?
                    </li>
                    <li>
                      Can you analyze why our matrix structure is not working
                      adequately?
                    </li>
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  A grasp from our services:
                  <ul>
                    <li>Organizational Health Scan</li>
                    <li>Organizational Planning & Analytics</li>
                  </ul>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={"649px"}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "85%"
              }}
            >
              <div style={{ flex: 2, textAlign: "center" }}>
                <img src={blueW} style={{ width: "50%" }} />
              </div>
              <div style={{ width: 50 }} />
              <div style={{ flex: 10 }}>
                <i style={{ fontSize: "1.15em" }}>
                  Applying Widen’s Organization Architecture methodology, we
                  deep dive into the tangible & non-tangible aspects of your
                  organization’s, provide objective insights and help surface
                  challenges and opportunities.
                </i>
                <div style={{ paddingTop: 50 }}>
                  Examples of client requests:
                  <ul>
                    <li>
                      Market conditions have pushed us to review our operating
                      strategy. Can you help us understand if and how that will
                      affect our future structure and required capabilities?
                    </li>
                    <li>
                      We’re in full change mode, but our efforts don’t trickle
                      down to the floor. Can you help us identify what’s getting
                      in the way?
                    </li>
                    <li>
                      Can you help us determine the right size, shape, and spend
                      of our workforce?
                    </li>
                    <li>
                      Can you analyze why our matrix structure is not working
                      adequately?
                    </li>
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  A grasp from our services:
                  <ul>
                    <li>Organizational Health Scan</li>
                    <li>Organizational Planning & Analytics</li>
                  </ul>
                </div>
              </div>
            </div>
          </MediaQuery>
          <hr
            key="dividerW1"
            style={{
              height: "1px",
              width: "85%",
              background: "rgba(255, 255, 255, 0.5)",
              border: "none",
              margin: "50px 0"
            }}
          />
          <MediaQuery minWidth={"650px"}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                width: "85%"
              }}
            >
              <div style={{ flex: 2 }}>
                <img src={yellowW} style={{ width: "100%" }} />
              </div>
              <div style={{ width: 50 }} />
              <div style={{ flex: 10 }}>
                <i style={{ fontSize: "1.15em" }}>
                  We (co-)design futureproof organization & people policies,
                  structures, processes & thinking frameworks to help you create
                  clarity, accountability and commitment across your business.
                </i>
                <div style={{ paddingTop: 50 }}>
                  Examples of client requests:
                  <ul>
                    <li>
                      How can we determine the level of responsibility of jobs
                      via an analytical grading methodology that is compatible
                      with major pay-data providers?
                    </li>
                    <li>
                      What are possible organization structure scenarios for our
                      target operating model, taking the following prisms into
                      account - pricing, customer, geography, technology,
                      people?
                    </li>
                    <li>
                      Can you design a flexible role framework and career paths?
                    </li>
                    <li>
                      How should we (re)design our reward policy in line with
                      our ambitions?
                    </li>
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  A grasp from our services:
                  <ul>
                    <li>Work Measurement</li>
                    <li>Organizational Structure</li>
                    <li>Role & Career Framework </li>
                    <li>Reward policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={"649px"}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "85%"
              }}
            >
              <div style={{ flex: 2, textAlign: "center" }}>
                <img src={yellowW} style={{ width: "50%" }} />
              </div>
              <div style={{ width: 50 }} />
              <div style={{ flex: 10 }}>
                <i style={{ fontSize: "1.15em" }}>
                  We (co-)design futureproof organization & people policies,
                  structures, processes & thinking frameworks to help you create
                  clarity, accountability and commitment across your business.
                </i>
                <div style={{ paddingTop: 50 }}>
                  Examples of client requests:
                  <ul>
                    <li>
                      How can we determine the level of responsibility of jobs
                      via an analytical grading methodology that is compatible
                      with major pay-data providers?
                    </li>
                    <li>
                      What are possible organization structure scenarios for our
                      target operating model, taking the following prisms into
                      account - pricing, customer, geography, technology,
                      people?
                    </li>
                    <li>
                      Can you design a flexible role framework and career paths?
                    </li>
                    <li>
                      How should we (re)design our reward policy in line with
                      our ambitions?
                    </li>
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  A grasp from our services:
                  <ul>
                    <li>Work Measurement</li>
                    <li>Organizational Structure</li>
                    <li>Role & Career Framework </li>
                    <li>Reward policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </MediaQuery>
          <hr
            key="dividerW2"
            style={{
              height: "1px",
              width: "85%",
              background: "rgba(255, 255, 255, 0.5)",
              border: "none",
              margin: "50px 0"
            }}
          />
          <MediaQuery minWidth={"650px"}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "85%"
              }}
            >
              <div style={{ flex: 2 }}>
                <img src={redW} style={{ width: "100%" }} />
              </div>
              <div style={{ width: 50 }} />
              <div style={{ flex: 10 }}>
                <i style={{ fontSize: "1.15em" }}>
                  We use our cross-industry implementation experience to support
                  you in re-wiring the organization, by planning and executing
                  the transformation journey.
                </i>
                <div style={{ paddingTop: 50 }}>
                  Examples of client requests:
                  <ul>
                    <li>
                      With our business aspirations in mind, can you help us
                      identify where to start and how to get there?
                    </li>
                    <li>
                      How to roll out our transformation plans, manage
                      workstreams and keep the oversight?
                    </li>
                    <li>Can you guide us through the people side of change?</li>
                    <li>
                      Help us facilitate workshops with & communication towards
                      unions, employees, senior managers.
                    </li>
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  A grasp from our services:
                  <ul>
                    <li>Transformation Roadmap</li>
                    <li>Implementation </li>
                  </ul>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={"649px"}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "85%"
              }}
            >
              <div style={{ flex: 2, textAlign: "center" }}>
                <img src={redW} style={{ width: "50%" }} />
              </div>
              <div style={{ width: 50 }} />
              <div style={{ flex: 10 }}>
                <i style={{ fontSize: "1.15em" }}>
                  We use our cross-industry implementation experience to support
                  you in re-wiring the organization, by planning and executing
                  the transformation journey.
                </i>
                <div style={{ paddingTop: 50 }}>
                  Examples of client requests:
                  <ul>
                    <li>
                      With our business aspirations in mind, can you help us
                      identify where to start and how to get there?
                    </li>
                    <li>
                      How to roll out our transformation plans, manage
                      workstreams and keep the oversight?
                    </li>
                    <li>Can you guide us through the people side of change?</li>
                    <li>
                      Help us facilitate workshops with & communication towards
                      unions, employees, senior managers.
                    </li>
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  A grasp from our services:
                  <ul>
                    <li>Transformation Roadmap</li>
                    <li>Implementation </li>
                  </ul>
                </div>
              </div>
            </div>
          </MediaQuery>
          <hr
            key="dividerW3"
            style={{
              height: "1px",
              width: "85%",
              background: "rgba(255, 255, 255, 0.5)",
              border: "none",
              margin: "50px 0"
            }}
          />
          <CustomButton
            backgroundColor="rgb(219, 80, 74)"
            hoverBackgroundColor="rgba(219, 80, 74, 0.5)"
            hoverBorder="2px solid rgba(219, 80, 74, 0.5)"
            onClick={() => (location.href = "mailto:info@widenadvisory.com")}
          >
            <div style={{ color: "#fff", padding: "10px 50px 10px 50px" }}>
              Let's connect
            </div>
          </CustomButton>
        </DefaultPageSection> */}
        <DefaultPageSection title="Grow With Us" refObj={this.growWithUsRef}>
          <TextBlock>
            {/* New Block */}
            {/* Organizational Transformation */}
            <b>Why join us?</b>
            <div>
              <br />
              We host a colorful mix of personalities, seniority levels, sector
              expertise and gender. But there is an invisible glue that connects
              our advisors. We love insight. We want to get to know our clients
              & colleagues better and learn from every person or situation that
              crosses our path. We love action. We work hard to deliver better
              results for our clients, and we know this will be rewarded. We
              love life. And strongly believe success in work goes hand in hand
              with care for what is dearest to each of us in life.
              <br /> <br />
            </div>
            {/* New Block */}
            {/* What does it mean to work with us? */}
            <b>What does it mean to work with us?</b>
            <div>
              <br />
              <div style={{ paddingLeft: 20 }}>
                <ul style={{ paddingLeft: 20, marginBlockStart: 0 }}>
                  <li>
                    An entrepreneurial, yet sustainable growth mind-set. We
                    invest in long term colleague- and client partnerships.
                    We’re immune to quarterly fever and profit warnings.
                  </li>
                  <li>
                    Becoming a trusted advisor for Davids and Goliaths in both
                    public, private and not-for profit sectors.
                  </li>
                  <li>
                    Exposure, impact and challenging organization design
                    projects with business and people leaders across sectors and
                    geographies.
                  </li>
                  <li>
                    Stepping out of your comfort zone, but with intensive peer
                    coaching, formal trainings and the help of your teammates.
                  </li>
                  <li>A culture of constant feedback.</li>
                  <li>
                    A flat, informal (internal) organization with an
                    international network of partners and subject matter
                    experts.
                  </li>
                  <li>
                    Hard work, and time for fun. We celebrate successes as we
                    hit growth milestones along the journey.
                  </li>
                </ul>
              </div>
              <br />
              <br />
            </div>
          </TextBlock>
          <img
            src={climbingAdventureImg}
            ref={this.climbingAdventureImgRef}
            style={{
              width: "100vw",
              height: 400,
              objectFit: "cover",
              objectPosition: `50% ${this.state.climbingAdventureImgY}%`
            }}
          />
          <TextBlock>
            {/* New Block */}
            {/* Current Openings */}
            <b>Current Openings</b>
            <div>
              <br />
              <div style={{ paddingLeft: 20 }}>
                <div>
                  <b>Experienced professionals:</b>
                  <br />
                  You’re a seasoned industry expert or an advisor that grew up
                  in a big firm? You no longer believe in the traditional
                  consulting model, and prefer client impact over internal
                  politics? Co-creating bespoke solutions, rather than
                  selling/delivering of the shelf? You love boutique characters
                  and believe agile beats big? You want to shape the future by
                  contributing to compelling new insights and thought
                  leadership, by generating new solutions and business, and by
                  leveraging the combined power and capabilities of your network
                  and Widen’s partner ecosystem?
                </div>
                <br />
                <div>
                  <b>Entrepreneurial wildcard:</b>
                  <br />
                  You think we’re missing something in our value proposition or
                  portfolio? You believe your product, service or intellectual
                  property will add value to our clients? You want to become a
                  member of our team to help clients with what you’re passionate
                  about or what you believe will reshape the consulting market?
                  And you’re able to back up your promises?
                  <br />
                  Then hit the wildcard. Trigger us. And we’ll take the time to
                  listen to your ideas or what you believe you can bring to the
                  table. We’re entrepreneurs as well and will play open cards
                  with you – in a confidential conversation.
                </div>
              </div>
            </div>
          </TextBlock>
          <div style={{ height: 50 }} />
          <CustomButton
            backgroundColor="rgb(219, 80, 74)"
            hoverBackgroundColor="rgba(219, 80, 74, 0.5)"
            hoverBorder="2px solid rgba(219, 80, 74, 0.5)"
            onClick={() => (location.href = "mailto:jobs@widenadvisory.com")}
          >
            <div style={{ color: "#fff", padding: "10px 50px 10px 50px" }}>
              Do you recognize yourself? Let's connect!
            </div>
          </CustomButton>
        </DefaultPageSection>
        <Footer
          refObj={this.connectRef}
          onHome={() => this.scrollToRef(this.homeRef)}
          onAbout={() => this.scrollToRef(this.aboutRef)}
          onHowWeWork={() => this.scrollToRef(this.howWeWorkRef, 150)}
          onOurCompetenceNetwork={() =>
            this.scrollToRef(this.ourCompetenceNetworkRef, 150)
          }
          onOurValues={() => this.scrollToRef(this.ourValuesRef, 150)}
          onOurStory={() => this.scrollToRef(this.ourStoryRef, 150)}
          onConnect={() => (location.href = "mailto:info@widenadvisory.com")}
          onGrowWithUs={() => this.scrollToRef(this.growWithUsRef, 150)}
          onWhatWeDo={() => this.scrollToRef(this.whatWeDoRef, 150)}
        />
      </div>
    );
  }
}

export default HomePage;
