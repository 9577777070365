import * as React from "react";
import logo from "../images/bannerLogo.png";
import img1 from "../images/home_image_slider/home_1.jpg";
import img2 from "../images/home_image_slider/home_2.jpg";
import img3 from "../images/home_image_slider/home_4.jpg";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";

class HomeImageScroller extends React.Component<{}, { index: number }> {
  state: { index: number };

  readonly images: Array<string> = [img1, img2, img3];

  constructor(props: {}) {
    super(props);
    this.state = {
      index: 0
    };
  }

  render() {
    const height: number = 350;
    return (
      <div>
        <div style={{ position: "relative" }}>
          <div style={{ position: "relative", height, width: "100%" }}>
            <img
              src={this.images[this.state.index]}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: height,
                objectFit: "cover",
                objectPosition: "50% 40%"
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: height,
                backgroundColor: "rgba(0,0,0,0.6)"
              }}
            />
            <div />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "absolute",
              zIndex: 3,
              top: 0,
              height: height,
              width: "100%"
            }}
          >
            {/* <MdChevronLeft
              size={height * 0.2}
              style={{ flex: 1 }}
              color="rgba(255, 255, 255, 0.75)"
              onClick={_ => {
                if (this.state.index - 1 < 0) {
                  this.setState({ index: this.images.length - 1 });
                } else {
                  this.setState({ index: --this.state.index });
                }
              }}
            /> */}
            <div style={{ flex: 5, color: "#fff" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 30,
                  textAlign: "center"
                }}
              >
                Stronger organization. Better business.
              </div>
            </div>
            {/* <MdChevronRight
              size={height * 0.2}
              style={{ flex: 1 }}
              color="rgba(255, 255, 255, 0.75)"
              onClick={_ => {
                if (this.state.index + 1 >= this.images.length) {
                  this.setState({ index: 0 });
                } else {
                  this.setState({ index: ++this.state.index });
                }
              }}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default HomeImageScroller;
