import * as React from "react";
import logo from "../images/bannerLogo.png";

import MediaQuery from "react-responsive";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

interface TopMenuBarProps {
  onHome: () => void;
  onAbout: () => void;
  onOurStory: () => void;
  onOurCompetenceNetwork: () => void;
  onOurValues: () => void;
  onHowWeWork: () => void;
  onWhatWeDo: () => void;
  onGrowWithUs: () => void;
  onConnect: () => void;
}

interface TopMenuBarState {
  mobileMenuIsOpen: boolean;
}

class TopMenuBar extends React.Component<TopMenuBarProps, TopMenuBarState> {
  constructor(props: TopMenuBarProps) {
    super(props);
    this.state = { mobileMenuIsOpen: false };
    this.toggleHamburgerMenu = this.toggleHamburgerMenu.bind(this);
  }

  navElem(text: string, onClick: () => void, mobilePadding?: boolean) {
    const padding = mobilePadding ? "10px 0" : "0";
    return (
      <div
        onClick={onClick}
        style={{
          cursor: "pointer",
          padding
        }}
      >
        {text}
      </div>
    );
  }

  toggleHamburgerMenu(mobileMenuIsOpen?: boolean) {
    mobileMenuIsOpen = mobileMenuIsOpen || !this.state.mobileMenuIsOpen;
    this.setState({ mobileMenuIsOpen });
  }

  render() {
    const fullSizedMenu = (
      <div
        style={{
          flex: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          color: "rgb(11, 40, 58)",
          fontSize: "16px"
        }}
      >
        {this.navElem("Home", this.props.onHome)}
        {this.navElem("About", this.props.onAbout)}
        {this.navElem("Our Story", this.props.onOurStory)}
        {this.navElem(
          "Our Competence Network",
          this.props.onOurCompetenceNetwork
        )}
        {this.navElem("Our Values", this.props.onOurValues)}
        {this.navElem("How We Work", this.props.onHowWeWork)}
        {this.navElem("What We Do", this.props.onWhatWeDo)}
        {this.navElem("Grow with us", this.props.onGrowWithUs)}
        {this.navElem("Connect", this.props.onConnect)}
      </div>
    );

    const mobileMenu = (
      <div style={{ textAlign: "center", paddingTop: 20 }}>
        {this.navElem("Home", this.props.onHome)}
        {this.navElem("About", this.props.onAbout)}
        {this.navElem("Our Story", this.props.onOurStory)}
        {this.navElem(
          "Our Competence Network",
          this.props.onOurCompetenceNetwork
        )}
        {this.navElem("Our Values", this.props.onOurValues)}
        {this.navElem("How We Work", this.props.onHowWeWork)}
        {this.navElem("What We Do", this.props.onWhatWeDo)}
        {this.navElem("Grow with us", this.props.onGrowWithUs)}
        {this.navElem("Connect", this.props.onConnect)}
      </div>
    );

    return (
      <div>
        <div
          style={{
            padding: "20px 50px 20px 50px",
            borderBottom: "1px solid #000"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <img
              src={logo}
              style={{
                maxWidth: "160px",
                maxHeight: "88.44px",
                width: "auto",
                height: "auto"
              }}
            />
            <div style={{ flex: 2 }} />
            <MediaQuery minWidth={1240}>{fullSizedMenu}</MediaQuery>
            <MediaQuery maxWidth={1239}>
              <div
                onClick={() => this.toggleHamburgerMenu()}
                style={{
                  textAlign: "left",
                  cursor: "pointer"
                }}
              >
                <FontAwesomeIcon
                  icon={!this.state.mobileMenuIsOpen ? faBars : faTimes}
                />
              </div>
            </MediaQuery>
          </div>
          <MediaQuery maxWidth={1239}>
            {this.state.mobileMenuIsOpen ? mobileMenu : <div />}
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default TopMenuBar;
